import React from 'react'

import Layout from '../components/layout'
import Header from '../components/header'
import ContactForm from '../components/ContactForm'

const serviceDescriptionData = {
  commercial: {
    title: 'Commercial and Residential Wiring',
    description: 'We provide a range of wiring services for commercial and residential properties, including:',
    list: [
      'A full & part re-wiring of your property',
      'Installing socket, lighting and all other electrical points',
      'Central heating controls',
      'Extra sockets or USB sockets'
    ]
  },
  upgrades: {
    title: 'Upgrades and Installations',
    description: 'Our team specializes in upgrading existing electrical setups and installing new appliances and fixtures, such as:',
    list: [
      'Consumer unit/fuse box upgrades',
      'Appliance installation',
      'Electrical showers'
    ]
  },
  inspections: {
    title: 'Inspections and Maintenance',
    description: 'Regular inspections and maintenance are essential for electrical safety; we offer services including:',
    list: [
      'Fault finding',
      'Landlord certifications',
      'Electrical inspections and maintenance',
      'PAT testing'
    ]
  },
  lighting: {
    title: 'Lighting Solutions',
    description: 'From installing new fixtures to upgrading your garden lighting, we offer a variety of lighting services like:',
    list: [
      'Light fitting change',
      'Garden lighting and sockets',
      'Extra lights or spotlights'
    ]
  },
  safety: {
    title: 'Safety and Security',
    description: 'We prioritize your safety and offer installations and certifications to keep your property secure, such as:',
    list: [
      'Smoke alarms',
      'Camera doorbells',
      'Certification'
    ]
  }
}

const Services = (props) => (
  <Layout>
    <Header siteTitle={'Norfolk Current'} />

    <h2 className="mb-0 text-4xl font-semibold text-center text-gray-900 sm:mb-20">Our Services</h2>

    <section id="commercial" className="bg-white">

      <div className="pt-8 pb-8 lg:pt-0 lg:pb-16 px-4 mx-auto max-w-screen-lg">
        <div className="md:grid md:grid-cols-2 md:gap-10">

          <ServiceDescription title={serviceDescriptionData.commercial.title} description={serviceDescriptionData.commercial.description} list={serviceDescriptionData.commercial.list} />

          <ServiceImage imageURL="/plug2.jpg" />

        </div>
        <hr className="hidden sm:block mt-16"/>
      </div>
    </section>

    <section id="upgrades" className="bg-white">

      <div className="pt-4 pb-4 sm:pt-8 sm:pb-8 lg:pt-0 lg:pb-16 px-4 mx-auto max-w-screen-lg">
        <div className="md:grid md:grid-cols-2 md:gap-10">

        <ServiceImage imageURL="/fusebox.jpg" />

          <ServiceDescription title={serviceDescriptionData.upgrades.title} description={serviceDescriptionData.upgrades.description} list={serviceDescriptionData.upgrades.list} />

        </div>
        <hr className="hidden sm:block mt-16"/>
      </div>
    </section>

    <section id="inspections" className="bg-white">

    <div className="pt-4 pb-4 sm:pt-8 sm:pb-8 lg:pt-0 lg:pb-16 px-4 mx-auto max-w-screen-lg">
        <div className="md:grid md:grid-cols-2 md:gap-10">

          <ServiceDescription title={serviceDescriptionData.inspections.title} description={serviceDescriptionData.inspections.description} list={serviceDescriptionData.inspections.list} />

          <ServiceImage imageURL="/pat.jpg" />

        </div>
        <hr className="hidden sm:block mt-16"/>
      </div>
    </section>

    <section id="lighting" className="bg-white">

    <div className="pt-4 pb-4 sm:pt-8 sm:pb-8 lg:pt-0 lg:pb-16 px-4 mx-auto max-w-screen-lg">
        <div className="md:grid md:grid-cols-2 md:gap-10">

        <ServiceImage imageURL="/light.jpg" />

          <ServiceDescription title={serviceDescriptionData.lighting.title} description={serviceDescriptionData.lighting.description} list={serviceDescriptionData.lighting.list} />

        </div>
        <hr className="hidden sm:block mt-16"/>
      </div>
    </section>

    <section id="safety" className="bg-white">

    <div className="pt-4 pb-4 sm:pt-8 sm:pb-8 lg:pt-0 lg:pb-16 px-4 mx-auto max-w-screen-lg">
        <div className="md:grid md:grid-cols-2 md:gap-10">

          <ServiceDescription title={serviceDescriptionData.safety.title} description={serviceDescriptionData.safety.description} list={serviceDescriptionData.safety.list} />

          <ServiceImage imageURL="/smoke2.jpg" />

        </div>
      </div>
    </section>

    {/* ------ Contact Form ------- */}

    <div className="pt-8 lg:pt-0 px-4 mx-auto max-w-screen-lg">
      <p className="mb-8 lg:mb-14 font-medium text-center sm:text-xl">Interested in any of these services? Give us a call at
      <a href="tel:07469469586" className="text-right text-blue-700 hover:underline"> 07469 469586 </a>
      or email Ciaran at
      <a href="mailto:ciarant@norfolkcurrent.co.uk" className="text-right text-blue-700 hover:underline"> ciarant@norfolkcurrent.co.uk </a>
      or just fill out the contact form below with your query.</p>
    </div>

    <section className="bg-white">
      <div className="container pt-8 pb-8 lg:pt-0 mx-auto lg:pb-16 px-4 max-w-screen-md">
        <ContactForm />
      </div>
    </section>

  </Layout>
)

export default Services

function ServiceDescription ({ title, description, list }) {
  return (
    <div>
      <h3 className="mt-5 text-xl font-semibold leading-6 text-black">{title}</h3>
      <div className="sm:hidden mt-5">
        <img className="m" src="/lighting.jpg" alt="logo" />
      </div>
      <p className="pt-4 text-lg text-gray-800">
        {description}
      </p>
      <ul className="list-disc text-lg pl-4 pt-4">
        {list.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  )
}

function ServiceImage ({ imageURL }) {
  return (
    <div className="hidden sm:block">
      <div className="relative w-full h-64">
        <img className="absolute top-0 left-0 w-full h-full object-cover" src={imageURL} alt="logo" />
      </div>
    </div>
  )
}
